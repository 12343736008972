<template>
  <div>
    <textarea class="w-full" :value="props.filter.value" @change="onChange"></textarea>
  </div>
</template>

<script setup lang="ts">
  import type { ColumnFilterProps, ColumnFilterEmits } from "../../lib/interfaces";

  const props = defineProps<ColumnFilterProps>();
  const emit = defineEmits<ColumnFilterEmits>();

  const onChange = (evt: Event) => {
    const input: HTMLInputElement = evt.target;

    emit("update:filter", {
      filterName: props.column.name,
      data: input.value,
    });
  };
</script>

<style scoped></style>
